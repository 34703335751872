import React from 'react';
import request from './request';
import config from './config';
let  axios = {
  get(url, params, headers,type) {
  	let options = {};
  	params && (options.params = params);
  	headers && (options.headers = headers);
    if(type===1){
      return request.get(config+url, options);
    }else{
      return request.get(url, options);
    }
  },
  post(url, params, headers,type) {
  	let options = {};
  	headers && (options.headers = headers);
    if(type===1){
      return request.post(config+url, params, options);
    }else{
      return request.post(url, params, options);
    }

  }
}
React.$http = axios
