import React, {Component} from 'react';
import DocumentMeta from 'react-document-meta';
import { CanvasGame } from '../packages/index.js';
class Game extends Component {
  constructor(props) {
    super(props);
    this.state={ }
  }
  render(){
    console.log(this.props)
    const meta = {
      title: 'ZMZUI-react - 一款超简洁react.js的模块化开发的PC网站快速成型UI组件库',
      description: '追梦猪旗下开发基于react.js的UI组件库,实现快速搭建网站应用,集成零散插件,帮助开发者快速开发,如没有相应插件,可联系作者进行组件开发!',
      meta: {
        charset: 'utf-8',
        name: {
          keywords: 'react,react.js,react.js插件,react.js插件开发,reactUI,reactUI组件,reactUI组件开发'
        }
      }
    };
    return (
      <DocumentMeta {...meta}>
        <div className="zmz-react" style={{'background':'#000','height':'80vh','textAlign':'center'}}>
        <CanvasGame></CanvasGame>
        </div>
      </DocumentMeta>
    );
  }
}
export default Game;
