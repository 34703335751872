import React, {Component} from 'react';
import { Textarea, Button, Tab, Alert } from '../packages/index.js'
class Demo2 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data:'http://www.zhuimengzhu.com',
      index:'1'
    };

  }
  render(){
    return (
      <div className="zmz-react-json">
        <Tab active={this.state.index} handleTab={(tab) => {this.handleTabClick(tab)}}>
          <Tab.item title="URL编码/解码" index="1">
            <div className="zmz-react-json__textarea">
              <Textarea defaultvalue={this.state.data} handleTextarea={this.handleChang.bind(this)}/>
            </div>
            <div className="zmz-react-json__button">
              <span onClick={()=>{this.handleCode()}}>
                <Button type="success" disabled={true} active={true} >UrlEncode编码</Button>
              </span>
              <span onClick={()=>{this.handleDecode()}}>
                <Button type="info" disabled={true} active={true} >UrlDecode解码</Button>
              </span>
              <span onClick={()=>{this.handleClear()}}>
                <Button type="info" disabled={true} active={true} >清空</Button>
              </span>
            </div>
            <div className="zmz-mt-20"><Alert
                        type="error"
                        title="带辅助性文字介绍"
                        description="这是一句绕口令：黑灰化肥会挥发发灰黑化肥挥发；灰黑化肥会挥发发黑灰化肥发挥。 黑灰化肥会挥发发灰黑化肥黑灰挥发化为灰……" /></div>
          </Tab.item>
          <Tab.item title="UTF-8编码/解码" index="2">
            <div className="zmz-react-json__textarea">
              <Textarea defaultvalue={this.state.data} handleTextarea={this.handleChang.bind(this)}/>
            </div>
            <div className="zmz-react-json__button">
              <span onClick={()=>{this.handleClickencode()}}>
                <Button type="success" disabled={true} active={true} >UTF-8编码</Button>
              </span>
              <span onClick={()=>{this.handleClickdecode()}}>
                <Button type="info" disabled={true} active={true} >UTF-8解码</Button>
              </span>
              <span onClick={()=>{this.handleClear()}}>
                <Button type="info" disabled={true} active={true} >清空</Button>
              </span>
            </div>
          </Tab.item>
          <Tab.item title="角色管理" index="3">角色管理3</Tab.item>
          <Tab.item title="定时补偿任务" index="4">定时补偿任务4</Tab.item>
        </Tab>
      </div>
    );
  }
  handleTabClick (index) {
    console.log(index)
    this.setState({
      index:index
    })
  }
  handleChang(event){
    console.log(event)
    this.setState({
      data:event.target.value
    })
  };
  handleCode = () => {
    var encodeURI = encodeURIComponent(this.state.data)
    this.setState({
      data:encodeURI
    })
  };
  handleClear = () => {
    this.setState({
      data:''
    })
  };
  handleDecode = () => {
    this.setState({
      data:decodeURIComponent(this.state.data)
    })
  };
  componentDidMount=()=>{
    console.log(2);
    var params = {
      url:'我是请求的参数'
    }
    React.$http.get('/music/api/wy/?key=523077333&id=%E6%8A%96%E9%9F%B3%E7%83%AD%E6%AD%8C&type=so&cache=0&nu=10',params).then(function(res) {
					console.log(res)
					if(res.data.Code === 0){
					}else{
					}
				})
  };
}

export default Demo2;
