import React, {Component} from 'react';
class Textarea extends Component {
  constructor(props) {
    super(props);
    // 为了在回调中使用 `this`，这个绑定是必不可少的
    this.state = {
      value: ''
    };
  }
  render() {
    return(
        <div className="zmz-textarea">
          <textarea
            className="zmz-textarea__textarea zmz-limit__over"
            rows="10" cols="20"
            value={this.props.defaultvalue} onChange={this.props.handleTextarea}
            >
          </textarea>
        </div>
    )
  }
}
export default Textarea;
