import React, {Component} from 'react';
export default class Tab extends Component {
  render(){
    return (
      <div className='zmz-tab'>
        <div className='zmz-tab__nav' >
          <div className='zmz-tab__nav--line' >
          {this.props.children.map((item, index) =>
            <div className={`zmz-tab__nav--item ${item.props.index===this.props.active?'is-active':''}`} key={index} onClick={()=>{this.props.handleTab(item.props.index,item.props.title)}}>{item.props.title}</div>
          )}
          </div>
        </div>
        <div className='zmz-tab__content'>
          {this.props.children.map((item, index) =>
            <div
              className="zmz-tab__content--item"
              style={{"display":`${item.props.index===this.props.active?"block":"none"}`}}
              key={index} >
              {item.props.children}
            </div>
          )}
        </div>
      </div>
    );
  }
}
