import React, {Component} from 'react';
export default class Alert extends Component {
  render(){
    console.log(this)
    let className = ''
    switch(this.props.type){
        case 'success':
        className = "zmz-alert--success";
        break;
        case 'warning':
        className = "zmz-alert--warning";
        break;
        case 'error':
        className = "zmz-alert--error";
        break;
        case 'confirm':
        className = "zmz-alert--confirm";
        break;
        case 'info':
        className = "zmz-alert--info";
        break;
        default:
        className = "zmz-alert--default";
    }
    return (
      <div className={`zmz-alert ${className} ${this.props.center?'is-center':''}`}
           >
        <i className={`zmz-alert__icon ${this.props.showIcon?this.props.showIcon:''}`}
           ></i>
        <div className="zmz-alert__content">
          <span className="zmz-alert__title isBoldTitle"
                >{this.props.title}</span>
          <p className="zmz-alert__description"
             >{this.props.description}</p>
          <i
             className={`zmz-alert__closebtn ${ this.props.closeText ? 'is-customed' : 'zmz-icon--close'}`}
             >{this.props.closeText?this.props.closeText:''}</i>
        </div>
      </div>
    );
  }
}
