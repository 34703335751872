import React, {Component} from 'react';
export default class CanvasConnection extends Component {
  constructor(props) {
    super(props);
    this.state={ }
  }
  render(){
    return (
      <canvas id="submit">您的浏览器不支持此标签,请更换最新浏览器</canvas>
    );
  }
  //canvas 动画
  init () {
    // 初始化函数
    this.canvas = document.getElementById('submit');
    this.canvas.width =  document.documentElement.clientWidth||document.body.clientWidth||window.innerWidth;
    this.canvas.height = document.documentElement.clientHeight||document.body.clientHeight||window.innerHeight;
    this.canvas.style.position = 'fixed';
    this.canvas.style.top = '0';
    this.canvas.style.left = '0';
    //
    this.mouseDis = 35;
    //
    this.mousePos = [0, 0];
    //
    this.maxspeedx = 0;
    // 重力
    this.gravity = 0.5;
    //速度
    this.speedx = 0;
    // 小水珠数组
    this.randomDraw = [];
    // 雨滴数组
    this.randomDrawList = [];
    // 雨滴数量
    this.lineNum = 3;
    this.ctx = this.canvas.getContext('2d');
    // 合并原始位置数组和鼠标划过位置
    //默认显示
    this.update()
    window.onmousemove =  (e) => {
      //  设置mousePos 等于 鼠标坐标
      //  e.clientX 为距离 浏览器窗口可视区域 左边的距离
      //  e.clientY 为距离 浏览器窗口可视区域 上边的距离
      this.mousePos[0] = e.clientX;
      this.mousePos[1] = e.clientY;

      // 通过鼠标位置，设置 maxspeedx的值，取值范围是 -1 到 1
      // maxspeedx的值，关系到
      // 1、雨滴的方向
      // 2、雨滴下落的方向
      // 3、雨滴下落方向 跟随 鼠标移动方向变化的速度
      // 4、小水珠的移动方向
      // 值越接近1，表示方向越向右
      // 值越接近-1，表示方向越向左
      this.maxspeedx = (e.clientX - this.canvas.clientWidth / 2) / (this.canvas.clientWidth / 2);
    }
    // 监听窗口变化
    window.addEventListener('resize',() =>{
      this.canvas.width =  document.documentElement.clientWidth||document.body.clientWidth||window.innerWidth;
      this.canvas.height = document.documentElement.clientHeight||document.body.clientHeight||window.innerHeight;
    })
  }
  renders() {
    // 视图
    // 画一个和可视区域一样大的矩形
    this.ctx.fillStyle = '#000';
    this.ctx.fillRect(0, 0, this.canvas.width, this.canvas.width);

    // 画雨滴效果
    this.ctx.lineWidth = 5;
    this.randomDrawList.forEach((line) => {
      this.ctx.strokeStyle = line.color;
      this.ctx.beginPath();
      this.ctx.moveTo(line.posx, line.posy);

      // * this.speedx 用来控制雨滴方向
      // 使 雨滴方向 和 鼠标移动方向相同
      this.ctx.lineTo(line.posx + line.h * this.speedx, line.posy + line.h);
      this.ctx.stroke();
    });

    // 画雨滴散开形成小水珠效果
    this.ctx.lineWidth = 1;
    this.ctx.strokeStyle = "#fff";
    this.randomDraw.forEach((e) => {
      this.ctx.beginPath();
      this.ctx.arc(e.posx, e.posy, e.radius, Math.random() * Math.PI * 2, 1 * Math.PI);
      this.ctx.stroke();
    });

    // 解开注释，可看见鼠标范围
      // this.ctx.beginPath();
      // this.ctx.arc(this.mousePos[0], this.mousePos[1], this.mouseDis, 0, 2 * Math.PI);
      // this.ctx.stroke();
  }
  update() {
    this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);
    // 如果保存小水珠的数组有内容
    if (this.randomDraw.length > 0) {
      // 遍历保存小水珠的数组
      this.randomDraw.forEach((e) => {
        //设置e.vx，vx表示x坐标变化的速度
        // (this.speedx)/2 是为了，让小水珠 在x轴的移动距离短一点，看上去更真实点
        // 也使 小水珠的移动方向 和 雨滴方向，雨滴下落方向，鼠标移动方向相同
        e.vx = e.vx + (this.speedx / 2);
        e.posx = e.posx + e.vx;

        //设置e.vy，vy表示y坐标变化的速度
        // e.vy的范围是-3 到 -9，而这时e.posy（y坐标）一定是正值，所以 e.posy的值会先减小后增大
        // 也就是实现 雨滴散成小水珠，小水珠会先上升后下降的效果
        e.vy = e.vy + this.gravity;
        e.posy = e.posy + e.vy;

        // 如果 小水珠y坐标 大于 可视区域的高度，设置die属性为true
        // 小水珠如果超出可视区域就删除掉
        if (e.posy > this.canvas.clientHeight) {
          e.die = true;
        }
      });
    }

    // 删除 die属性为ture 的数组成员
    // 可视区域外的小水珠删除掉
    for (var i = this.randomDraw.length - 1; i >= 0; i--) {
      if (this.randomDraw[i].die) {
        this.randomDraw.splice(i, 1);
      }
    }

    // 设置下雨方向变换的速度，取值范围： -1 到 1
    // 当 this.speedx = maxthis.speedx时，下雨方向 会 随鼠标移动方向立即改变
    this.speedx = this.speedx + (this.maxspeedx- this.speedx) / 50;

    // 根据lineNum的值，画一定数量雨滴
    for (var j = 0; j < this.lineNum; j++) {
      // 调用drawLine 函数，参数是雨滴x坐标
      this.drawLine(Math.random() * 2 * this.canvas.width - (0.5 * this.canvas.width));
    }

    // 设置结束线，也就是雨滴散开 形成许多小水珠的位置
    var endLine = this.canvas.clientHeight - Math.random() * this.canvas.clientHeight / 5;

    // 遍历保存雨滴的数组
    this.randomDrawList.forEach((e)=>{

      // 利用勾股定理 确定一个范围，在这个范围内雨滴会散开形成小水珠
      // e.posx + this.speedx * e.h 是雨滴x坐标
      // e.posy + e.h 是雨滴y坐标
      var dis = Math.sqrt(((e.posx + this.speedx * e.h) - this.mousePos[0]) * ((e.posx + this.speedx * e.h) - this.mousePos[0]) +
        (e.posy + e.h - this.mousePos[1]) * (e.posy + e.h - this.mousePos[1]));

      // 如果在mouseDis区域内，就删除雨滴，画一些小水珠（圆弧）
      // 实现鼠标碰到雨滴，雨滴散成小水珠的效果
      if (dis < this.mouseDis) {
        // 删除 雨滴
        e.die = true;
        // 画一些小水珠（圆弧）
        this.madedrops(e.posx + this.speedx * e.h, e.posy + e.h);
      }

      // 如果雨滴超过 结束线，删除雨滴，画一些小水珠（圆弧）
      if ((e.posy + e.h) > endLine) {
        e.die = true;
        this.madedrops(e.posx + this.speedx * e.h, e.posy + e.h);
      }

      // 如果 雨滴 y坐标 大于 可视区域的高度，设置die属性为true
      // 如果 雨滴 超出可视区域就删除掉
      if (e.posy >= this.canvas.clientHeight) {
        e.die = true;
      } else {
        // 逐渐增加 雨滴 y坐标的值
        e.posy = e.posy + e.speed;

        // 变化雨滴 x坐标
        // * this.speedx 用来控制雨滴 下落 方向
        // 使 雨滴下落方向 和 鼠标移动方向相同
        e.posx = e.posx + e.speed * this.speedx;
      }
    });

    // 删除 die属性为ture 的数组成员
    // 鼠标区域内的，超过结束线的，可视区域外的雨滴删除掉
    for (var d = this.randomDrawList.length - 1; d >= 0; d--) {
      if (this.randomDrawList[d].die) {
        this.randomDrawList.splice(d, 1);
      }
    }

    // 渲染
    this.renders();
    // 递归调用 update，实现动画效果
    window.requestAnimationFrame(()=>{
        this.update()
    });
  }
  madedrops (x, y) {
    // 随机生成一个数 maxi
    // maxi 代表要画小水珠的数量
    var maxi = Math.floor(Math.random() * 5 + 5);
    for (var i = 0; i < maxi; i++) {
      this.randomDraw.push(this.createDrop(x, y));
    }
  }
  drawLine(e) {
     // 画雨滴
    // 随机生成 雨滴的长度
    var temp = 0.25 * (50 + Math.random() * 100);
    // 一个 line 对象，代表一个雨滴
    var line = {
      // 雨滴下落速度
      speed: 5.5 * (Math.random() * 6 + 3),
      // 判断是否删除，值为true就删除
      die: false,
      // 雨滴x坐标
      posx: e,
      // 雨滴y坐标
      posy: -50,
      // 雨滴的长度
      h: temp,
      // 雨滴的颜色
      color: this.getColor(Math.floor(temp * 255 / 75), Math.floor(temp * 255 / 75), Math.floor(temp * 255 / 75))
    };
    // 把创建好的line（雨滴）对象，添加到保存雨滴的数组
    this.randomDrawList.push(line);
  }
  createDrop (x, y) {
    // 一个 drop 对象，代表一个圆弧
    var drop = {
      // 判断是否删除，值为true就删除
      die: false,
      // 圆弧圆心的x坐标
      posx: x,
      // 圆弧圆心的y坐标
      posy: y,
      // vx 表示 x轴的值 变化的速度
      vx: (Math.random() - 0.5) * 8,
      // vy 表示 y轴的值 变化的速度 取值范围：-3 到 -9
      vy: Math.random() * (-6) - 3,
      // 圆弧的半径
      radius: Math.random() * 1.5 + 1
    };
    return drop;
  }
  getColor (r, g, b) {
    // 随机位置工具方法
    return "rgb(" + r + "," + g + "," + b + ")";
  }
  componentDidMount=()=>{
    // 挂载
    this.init()
  };
};
