import React, {Component} from 'react';
import PropTypes from 'prop-types';
class Button extends Component {
  static propTypes = {
    type:PropTypes.string,
    icon:PropTypes.string,
    round:PropTypes.bool,
    plain:PropTypes.bool,
    disabled:PropTypes.bool,
    active:PropTypes.bool
  }
  render() {
    var className = "";
    var iconName = "";
    switch(this.props.type){
        case 'success':
        className = "zmz-button--success";
        break;
        case 'warning':
        className = "zmz-button--warning";
        break;
        case 'error':
        className = "zmz-button--error";
        break;
        case 'confirm':
        className = "zmz-button--confirm";
        break;
        case 'info':
        className = "zmz-button--info";
        break;
        default:
        className = "zmz-button--default";
    }
    if (this.props.icon) {
      iconName = <i className={this.props.icon}></i>;
    }
    return(
        <button
        	className={`zmz-button ${className} ${this.props.round ?'is-round' : ''} ${this.props.plain ?'is-plain' : ''} ${this.props.disabled ?'is-disabled' : ''} ${this.props.active ?'is-active' : ''}`}
        >
        {iconName}
        	<span>
          {this.props.children}
        	</span>
        </button>
    )
  }
}
export default Button;
