import React, {
  Component
} from 'react';
export default class CanvasGame extends Component {
  constructor(props) {
    super(props);
    this.state = {}
  }
  render() {
    return ( <
      canvas id = "submit"
      data = {
        this.props.data
      } > 您的浏览器不支持此标签, 请更换最新浏览器 < /canvas>
    );
  }
  // 初始化
  initFun() {
    // 主画布
    this.canvassubmit = document.getElementById("submit");
    this.submit2d = this.canvassubmit.getContext("2d");
    this.canvassubmit.width = document.documentElement.clientWidth > 640 ? 640 : document.documentElement.clientWidth;
    this.canvassubmit.height = document.documentElement.clientHeight > 500 ? 500 : document.documentElement.clientHeight;
    // 地形
    this.terrain = "20|20"
    this.w = this.terrain.split("|")[0];
    this.h = this.terrain.split("|")[1];
    // 地形数组
    this.terrainArr = []
    // 虚拟怪画布
    this.momoryCanvas = document.createElement("canvas");
    this.mctx = this.momoryCanvas.getContext("2d");
    this.momoryCanvas.height = this.h * 20;
    this.momoryCanvas.width = this.w * 240;

    // 人物画布
    this.userCanvas = document.createElement("canvas");
    this.uctx = this.userCanvas.getContext("2d");
    this.userCanvas.width = this.w * 240;
    this.userCanvas.height = this.h * 20;
    // 人物信息
    this.userObj = this.user(0, 0, 20, 20, "0|0", "Image/terrain/USER.png");
    this.userObj.zdNum = 5;
    // 怪运动速度
    this.speed = 50;
    // 前进后退视图显示
    this.viewX = 0;
    // 全局调用动画初始化
    this.animateA = null;
    // 全局调用人物是否移动
    this.userMove = false;
    // 关卡
    this.gs = 0
    // 地图矩阵数组
    this.mapUI = [
      [
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 1, 1, 1, 1, 1, 0, 0, 1, 1, 1, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 1, 1,
        1, 1, 1, 0, 0, 1, 1, 1, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 1, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 1, 1, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 1, 1, 1, 1, 1, 1, 1, 1,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 1, 1, 1, 1, 1,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 1, 1, 0, 0, 1, 1, 1, 0, 0, 0, 1,
        0, 0, 1, 1, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 1, 1, 0, 0, 1, 1, 1, 0, 0, 0, 1, 0, 0, 1, 1, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 1, 1,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1,
        0, 0, 0, 0, 1, 1, 1, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 1, 1, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 1,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1,
        0, 0, 0, 0, 0, 0, 1, 1, 1, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 0, 0, 0, 0, 0, 0, 1, 1, 1, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 1, 1, 1, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 1,
        1, 1, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 1, 1, 1, 1, 1, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 1, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 1, 1, 0, 0, 0, 0, 0, 0, 0, 1, 1, 1, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 1,
        1, 1, 1, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 1, 1, 1, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1,
        0, 0, 0, 0, 0, 0, 0, 1, 1, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 1, 1, 1, 1, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 0, 0, 0, 0, 0, 0, 0, 1, 1, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 1, 1, 0, 0, 0, 0, 0, 0, 0, 1, 1, 1, 1,
        1, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 1, 1, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 1, 1, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 1, 1, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 1, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 1, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 1, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 1, 1, 0, 0, 0, 0, 0, 0, 0, 0, 1, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1,
        1, 1, 1, 0, 0, 1, 1, 1, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1,
        0, 0, 0, 0, 1, 0, 0, 0, 0, 0, 0, 0, 0, 1, 1, 0, 0, 0, 0, 0, 1, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 1, 1, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 1, 1, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 1, 1, 0, 0, 0, 0, 0, 1, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 1, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 1, 1, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 1, 1, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 1, 1, 1, 1, 1, 0, 0, 0, 0, 0, 0, 0, 0, 1, 1, 1, 1, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
        0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1,
        1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,
        1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,
        1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,
        1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,
        1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,
        1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,
        1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,
        1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,
        1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,
        1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,
        1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,
        1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,
        1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1,
        1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1
      ]
    ];
    this.monsterData = [
      [
        "180:320:20:20:/Image/monster/monster2.png:0:4x1:leftRight*zdFun:5",
        "100:320:20:20:/Image/monster/monster1.png:0:4x1:leftRight*zdFun:5",
        "160:0:20:20:/Image/monster/monster2.png:0:4x1:leftRight*zdFun:5",
        "200:0:20:20:/Image/monster/monster2.png:0:4x1:leftRight*zdFun:5",
        "1540:280:20:20:/Image/monster/monster2.png:0:4x1:leftRight*zdFun:5",
        "1460:80:20:20:/Image/monster/monster2.png:0:4x1:leftRight*zdFun:5",
        "280:0:20:20:/Image/monster/monster1.png:0:4x1:leftRight*zdFun:5",
        "420:320:20:20:/Image/monster/monster2.png:0:4x1:leftRight*zdFun:5",
        "820:320:20:20:/Image/monster/monster1.png:0:4x1:leftRight*zdFun:5",
        "1200:320:20:20:/Image/monster/monster1.png:0:4x1:leftRight*zdFun:5",
        "1620:320:20:20:/Image/monster/monster2.png:0:4x1:leftRight*zdFun:5",
        "840:0:20:20:/Image/monster/monster1.png:0:4x1:leftRight*zdFun:5",
        "860:0:20:20:/Image/monster/monster2.png:0:4x1:leftRight*zdFun:5",
        "880:0:20:20:/Image/monster/monster1.png:0:4x1:leftRight*zdFun:5",
        "900:0:20:20:/Image/monster/monster2.png:0:4x1:leftRight*zdFun:5",
        "960:0:20:20:/Image/monster/monster2.png:0:4x1:leftRight*zdFun:5",
        "1000:0:20:20:/Image/monster/monster1.png:0:4x1:leftRight*zdFun:5",
        "1150:160:20:20:/Image/monster/monster2.png:0:4x1:leftRight*zdFun:5",
        "1300:160:20:20:/Image/monster/monster1.png:0:4x1:leftRight*zdFun:5",
        "1400:160:20:20:/Image/monster/monster1.png:0:4x1:leftRight*zdFun:5",
        "1800:160:20:20:/Image/monster/monster2.png:0:4x1:leftRight*zdFun:5",
        "1900:320:20:20:/Image/monster/monster1.png:0:4x1:leftRight*zdFun:5",
        "2000:0:20:20:/Image/monster/monster2.png:0:4x1:leftRight*zdFun:5",
        "2100:0:20:20:/Image/monster/monster1.png:0:4x1:leftRight*zdFun:5",
        "2260:0:20:20:/Image/monster/monster2.png:0:4x1:leftRight*zdFun:5",
        "2300:0:20:20:/Image/monster/monster2.png:0:4x1:leftRight*zdFun:5",
        "2500:0:20:20:/Image/monster/monster1.png:0:4x1:leftRight*zdFun:5",
        "2800:320:20:20:/Image/monster/monster2.png:0:4x1:leftRight*zdFun:5",
        "3000:320:20:20:/Image/monster/monster1.png:0:4x1:leftRight*zdFun:5",
        "3100:320:20:20:/Image/monster/monster1.png:0:4x1:leftRight*zdFun:5",
        "3300:320:20:20:/Image/monster/monster2.png:0:4x1:leftRight*zdFun:5",
        "3600:0:20:20:/Image/monster/monster1.png:0:4x1:leftRight*zdFun:5",
        "4000:0:20:20:/Image/monster/monster2.png:0:4x1:leftRight*zdFun:5",
        "4200:0:20:20:/Image/monster/monster1.png:0:4x1:leftRight*zdFun:5",
        "4250:0:20:20:/Image/monster/monster2.png:0:4x1:leftRight*zdFun:5",
        "3800:0:20:20:/Image/monster/monster2.png:0:4x1:leftRight*zdFun:5",
        "3700:0:20:20:/Image/monster/monster1.png:0:4x1:leftRight*zdFun:5",
        "3400:160:20:20:/Image/monster/monster2.png:0:4x1:leftRight*zdFun:5",
        "2600:160:20:20:/Image/monster/monster1.png:0:4x1:leftRight*zdFun:5",
        "2700:160:20:20:/Image/monster/monster1.png:0:4x1:leftRight*zdFun:5",
        "2900:160:20:20:/Image/monster/monster2.png:0:4x1:leftRight*zdFun:5"
      ]
    ];
    this.momoryCanvas.width = this.w * 240;
    this.gsFun = [
      [
        function(O1) {
          console.log(O1)
          var ZX = O1.mapUI[O1.gs].WH.split("|")[0] * O1.terrain.split("|")[0];
          if (O1.userObj.x > ZX - (O1.canvassubmit.width * 0.8)) {
            if (O1.initBoss === false) {
              var monster = (ZX - 150) + ":320:20:20:Image/monster/monster3.png:0:4x2:-1:5:Ih=>0*Iw=>2";
              O1.BOSS = O1.initStrToMonster(monster, true);
              O1.monsterArr.push(O1.BOSS);
              O1.BOSS.defaultFun = "leftRight*skill_JumpKill";
            }
          }
          if (!O1.BOSS) {
            return;
          }
          if (O1.BOSS.death === true) {
            O1.gameIsEnd("恭喜过关!!");
            setTimeout(function() {
              window.location.reload()
            }, 2000);
            O1.BOSS = null;
          }
        },
        function(O1) {

        }
      ]
    ]
    //关卡时间
    this.targetTime = (new Date().getMinutes() + 5) * 60 + new Date().getSeconds();
    //倒计时时间
    this.timeEndSeconds = this.targetTime - 0;
    // 移动范围
    this.Around = []
    //怪物数组
    this.monsterArr = [];
    //子弹数组
    this.zdA = [];
    //怪物子弹数组
    this.zdB = [];
    for (var i = 0; i < this.mapUI.length; i++) {
      this.mapUI[i].WH = "240|20";
    }
    this.initTerrain();
    this.centerFun();
  }
  // 开始准备函数
  startFun() {
    //初始化函数
    this.initFun();
    clearInterval(this.centerTime);
    //中心计时器
    this.bgC();
  };
  // 渲染地图
  bgC() {
    //此处不加循环贴图会出现地形错误
    if (this.loaded === true) {
      this.centerTime = setInterval(() => {
        this.centerFun()
      }, this.speed);
      // 绘制地形
      this.drawTerrain();
      this.a();
    } else {
      setTimeout(() => {
        this.bgC();
      }, 10);
    }
  };
  // 闯关倒计时
  centerFun() {
    var dateNum = new Date();
    this.timeEndSeconds = this.targetTime - (dateNum.getMinutes() * 60 + dateNum.getSeconds());
    if (this.timeEndSeconds <= 0) {
      console.log("time is end");
      this.timeEndSeconds = 0;
    }
    dateNum = null;
    // 绘制人物信息
    this.drawUserObj();
    // 绘制怪物信息
    this.drawMonster();
    // 调用每个关数相关函数
    this.startGSGlobalFun();
    // 更新移动视图
    this.jtFun();
    //drawTerrain();
  };
  // 移动镜头
  jtFun() {
    if ((this.canvassubmit.width / 2) < (this.userObj.x + this.viewX) - 10) {
      this.rightViewX();
    } else if ((this.canvassubmit.width / 2) > (this.userObj.x + this.viewX) + 10) {
      this.leftViewX();
    }
  };
  // 向左移动
  leftViewX() {
    if (this.viewX >= 0) {
      this.viewX = 0;
    } else {
      this.viewX += 5;
    }
  };
  // 向右移动
  rightViewX() {
    if (this.viewX <= ((-this.momoryCanvas.width) + this.canvassubmit.width)) {
      this.viewX = ((-this.momoryCanvas.width) + this.canvassubmit.width);
    } else {
      this.viewX -= 5;
    }
  };
  // 运行每一个关数相对应的函数
  startGSGlobalFun() {
    for (var i = 0; i < this.gsFun[this.gs].length; i++) {
      this.gsFun[this.gs][i](this);
    }
  };
  // 绘制小怪
  drawMonster() {
    for (var i = 0; i < this.monsterArr.length; i++) {
      this.uctx.drawImage(this.monsterArr[i].img, this.monsterArr[i].Iw * (this.monsterArr[i].img.width / this.monsterArr[
          i].jzImg.split("x")[0]), this.monsterArr[i].Ih * (this.monsterArr[i].img.height / this.monsterArr[i].jzImg
          .split("x")[1]), (this.monsterArr[i].img.width / this.monsterArr[i].jzImg.split("x")[0]), (this.monsterArr[
          i].img.height / this.monsterArr[i].jzImg.split("x")[1]), this.monsterArr[i].x, this.monsterArr[i].y, this
        .monsterArr[i].w, this.monsterArr[i].h);
      this.monsterMoveType(this.monsterArr[i]);
      if (this.monsterArr[i].moveBottom(this.mapUI[this.gs]) === "death") {
        this.monsterArr.splice(i, 1);
      };
    };
    for (var h = 0; h < this.zdA.length; h++) {
      this.uctx.drawImage(this.zdA[h].img, this.zdA[h].x, this.zdA[h].y, this.zdA[h].w, this.zdA[h].h);
    }
    for (var j = 0; j < this.zdB.length; j++) {
      this.uctx.drawImage(this.zdB[j].img, this.zdB[j].x, this.zdB[j].y, this.zdB[j].w, this.zdB[j].h);
    }
    this.uctx.closePath();
    this.uctx.restore();
  };
  // 怪物移动类型
  monsterMoveType(o) {
    if (o.defaultFun === -1) {
      return 0;
    }
    var funName = o.defaultFun.split("*");
    for (var i = 0; i < funName.length; i++) {
      o[funName[i]](this.mapUI[this.gs], this);
    }
  };
  // 绘制主题背景
  drawGameBg() {
    this.submit2d.drawImage(this.momoryCanvas, 0, 0);
  };
  // 绘制矩阵地形bg
  drawTerrain() {
    var w = 0;
    var h = 0;
    for (var i = 0; i < this.terrainArr.length; i++) {
      w = this.terrainArr[i].WH.split("|")[0] * this.terrain.split("|")[0];
      h = this.terrainArr[i].WH.split("|")[1] * this.terrain.split("|")[1];
      this.mctx.drawImage(this.terrainArr[i].img, w, h, this.terrain.split("|")[0], this.terrain.split("|")[1]);
    }
  };
  // 绘制用户操作角色
  drawUserObj() {
    this.uctx.clearRect(0, 0, this.userCanvas.width, this.userCanvas.height);
    this.uctx.save();
    this.uctx.beginPath();
    this.uctx.drawImage(this.userObj.img, this.userObj.Iw * 32, this.userObj.Ih * 48, 32, 48, this.userObj.x, this.userObj
      .y, this.userObj.w, this.userObj.h);
    this.userObj.moveBottom(this.mapUI[this.gs]);
    if (this.userMove) {
      if (this.userObjLeft) {
        this.userObj.moveLeft(this.mapUI[this.gs]);
      }
      if (this.userObjRight) {
        this.userObj.moveRight(this.mapUI[this.gs]);
      }
      if (this.userObjTop) {
        this.userObj.moveTop(this.mapUI[this.gs]);
      }
    }
  };
  // 用户个人信息绘制
  drawUserUI() {
    this.submit2d.fillText("子弹数量:" + this.userObj.zdNum + "生命值" + this.userObj.SMZ + "剩余时间" + this.timeEndSeconds, -
      this.viewX + 10, 20 - (this.canvassubmit.height - this.momoryCanvas.height) / 2);
  };
  a() {
    this.submit2d.save();
    this.submit2d.beginPath();
    this.submit2d.clearRect(0, 0, this.canvassubmit.width, this.canvassubmit.height);
    this.submit2d.fillStyle = "rgba(255,255,255,0.8)";
    this.submit2d.fillRect(0, 0, this.canvassubmit.width, this.canvassubmit.height);
    this.submit2d.translate(this.viewX, (this.canvassubmit.height - this.momoryCanvas.height) / 2);
    //绘制背景
    this.drawGameBg();
    //绘制用户操作
    this.submit2d.drawImage(this.userCanvas, 0, 0);
    // 调用用户个人信息
    this.drawUserUI();

    this.submit2d.closePath();
    this.submit2d.restore();
    this.animateA = window.requestAnimationFrame(() => {
      this.a()
    });
    // 调用怪信息
    this.monsterUserObj();
  };
  // 判断碰撞
  isPZ(rect1, rect2) {
    var str = {};
    if (rect1.x > rect2.x && rect1.x < rect2.x + rect2.w) {
      str.leftRight = "left";
    } else if (rect1.x + rect1.w > rect2.x && rect1.x + rect1.w < rect2.x + rect2.w) {
      str.leftRight = "right"
    } else if (rect1.x === rect2.x) {
      str.leftRight = "right";
    } else if (rect1.x + rect1.w === rect2.x) {
      str.leftRight = "left"
    } else {
      str.leftRight = -1
    }
    if (rect1.y > rect2.y && rect1.y < rect2.y + rect2.h) {
      str.topBottom = "top";
    } else if (rect1.y + rect1.h > rect2.y && rect1.y + rect1.h < rect2.y + rect2.h) {
      str.topBottom = "bottom"
    } else if (rect1.y === rect2.y) {
      str.topBottom = "top"
    } else {
      str.topBottom = -1
    }
    return str;
  };
  // 怪物对象绘制
  monsterUserObj() {
    var pzObj = null;
    for (var i = 0; i < this.monsterArr.length; i++) {
      pzObj = this.isPZ(this.userObj, this.monsterArr[i]);
      if (pzObj.leftRight !== -1 && pzObj.topBottom !== -1) {
        if (pzObj.topBottom === "bottom") {
          if (this.userDebugger === true) {
            return 0;
          }
          if (this.monsterArr[i].death !== true && this.userObj.death !== true) {
            this.monsterArr[i].death = true;
            this.monsterArr[i].deathFun(this);
            this.userObj.zdNum++;
            var deathObj = this.userObj;
            deathObj.moveBottomBool = false;
            deathObj.movePoint({
              y: deathObj.y - 30
            }, 50, () => {
              deathObj.moveBottomBool = true;
              deathObj = null;
            });
          }
        } else if (pzObj.topBottom === "top") {
          if (this.monsterArr[i].death !== true) {
            if (this.userDebugger === true) {
              return 0;
            }
            if (this.userObj.SMZ <= 0) {
              this.userObj.death = true;
              this.userObj.deathFun(this);
            } else {
              this.userObj.SMZ--;
            }
          }
        }
      }
    }
    for (var h = 0; h < this.monsterArr.length; h++) {
      for (var w = 0; w < this.zdA.length; w++) {
        pzObj = this.isPZ(this.zdA[w], this.monsterArr[h]);
        if (pzObj.leftRight !== -1 && pzObj.topBottom !== -1) {
          var deathObj = this.monsterArr[h];
          deathObj.deathIng = true;
          deathObj.moveBottomBool = false;
          this.monsterArr[h].movePoint({
            x: this.monsterArr[h].x,
            y: this.monsterArr[h].y - 15
          }, 10, () => {
            deathObj.deathFun(this);
            deathObj.death = true;
            deathObj.moveBottomBool = true;
            deathObj = null;
          });
          for (var e = 0; e < this.zdA.length; e++) {
            if (this.zdA[e] === this.zdA[w]) {
              clearInterval(this.zdA[e].timmer);
              this.zdA.splice(e, 1);
            }
          }
        }
      }
    }
    for (var t = 0; t < this.zdB.length; t++) {
      pzObj = this.isPZ(this.zdB[t], this.userObj);
      if (pzObj.leftRight !== -1 && pzObj.topBottom !== -1) {
        if (this.userDebugger === true) {
          return 0;
        }
        if (this.userObj.SMZ <= 1) {
          this.userObj.death = true;
          this.userObj.deathFun(this);
          this.userObj.SMZ = 0;
        } else {
          this.userObj.SMZ--;
        }
        for (var f = 0; f < this.zdB.length; f++) {
          if (this.zdB[f] === this.zdB[t]) {
            clearInterval(this.zdB[f].timmer);
            this.zdB.splice(f, 1);
          }
        }
      }
    }
  };
  // 初始化canvas地形
  initTerrain() {
    //地形数组
    for (var i = 0; i < this.mapUI[this.gs].length; i++) {
      this.drawImg(this.mapUI[this.gs][i], i);
    }
    var momArr = null;
    for (i = 0; i < this.monsterData[this.gs].length; i++) {
      momArr = this.monsterData[this.gs][i].split(":");
      var Obj = {};
      if (typeof momArr[9] != "undefined") {
        var keys = momArr[9].split("*");
        for (var o = 0; o < keys.length; o++) {
          Obj[keys[o].split("=>")[0]] = keys[o].split("=>")[1] ? keys[o].split("=>")[1] : keys[o].split("=>")[0];
        }
      }
      this.monsterArr.push(this.monster(parseInt(momArr[0]), parseInt(momArr[1]), parseInt(momArr[2]), parseInt(
        momArr[3]), "0|0", momArr[4], parseInt(momArr[5]), momArr[6], momArr[7], parseInt(momArr[8]), Obj));
    }
    this.loadRes = [
      new Image()
    ];
    this.loadRes[0].src = "Image/zdA/zd1.gif";
    this.dT()
  };
  // 地图加载
  dT() {
    var loaded = 0;
    var loadedM = 0;
    var loadedRes = 0;
    for (var i = 0; i < this.terrainArr.length; i++) {
      if (this.terrainArr[i].img.complete) {
        loaded++;
      }
    }
    for (var j = 0; j < this.monsterArr.length; j++) {
      if (this.monsterArr[j].img.complete) {
        loadedM++;
      }
    }
    for (var h = 0; h < this.loadRes.length; h++) {
      if (this.loadRes[h].complete) {
        loadedRes++;
      }
    }
    if (loadedRes === this.loadRes.length && loadedM === this.monsterArr.length && loaded === this.terrainArr.length &&
      this.userObj.img.complete === true) {
      this.loaded = true;
    } else {
      setTimeout(() => {
        this.dT();
      }, 10);
    }
  };
  // 子弹类
  gjA(x, y, w, h, img) {
    var gjA = {}; //创建对象，对象属性赋值
    gjA.x = x; // x
    gjA.y = y; // y
    gjA.w = w; // 宽度
    gjA.h = h; // 高度
    if (typeof img === "string") {
      gjA.img = new Image();
      gjA.img.src = img;
    } else {
      gjA.img = img;
    }
    gjA.movePoint = (P, time, f, o) => {
      if (gjA.timmer) {
        clearInterval(gjA.timmer);
      }
      if (time === -1) {
        gjA.x = typeof P.x !== "undefined" ? P.x : gjA.x;
        gjA.y = typeof P.y !== "undefined" ? P.y : gjA.y;
        return 1;
      }
      var moveJGx = (gjA.x - P.x) / time;
      var moveJGy = (gjA.y - P.y) / time;
      var THIS = gjA;
      var num = 0;
      gjA.timmer = setInterval(() => {
        num++;
        if (num >= time) {
          if (typeof P.x !== "undefined") {
            THIS.x = P.x;
          }
          if (typeof P.y !== "undefined") {
            THIS.y = P.y;
          }
          clearInterval(THIS.timmer);
          if (f) {
            f(THIS);
          }
        } else {
          if (typeof P.x !== "undefined") {
            THIS.x = THIS.x - moveJGx
          }
          if (typeof P.y !== "undefined") {
            THIS.y = THIS.y - moveJGy;
          }
        }
        if (o && o.interFun) {
          o.interFun(THIS, o.constructor === this.user ? "zdA" : "zdB");
        }
      }, 1)
    }
    return gjA
  };
  // 用户操作类
  user(x, y, w, h, WH, img) {
    var user = {}; // 创建对象，对象属性赋值
    user.moveBottomBool = true;
    user.x = x; // x
    user.y = y; // y
    user.w = w; // 宽度
    user.h = h; // 高度
    user.Ih = 0;
    user.Iw = 0;
    /*对象计时器 如果对帧的概念不是很了解还是别看这个属性了*/
    user.timmer = 0;
    /*像做的加速度*/
    user.leftG = 10;
    /*向下移动速度*/
    user.vBottm = 0;
    /*最大速度*/
    user.vMaxBottm = 10;
    /*初始化速度*/
    user.vLeft = 0;
    /*最大移动速度*/
    user.vMaxLeft = 8;
    /*跳跃速度*/
    user.initJunpV = 20;
    /*正在死亡*/
    user.deathIng = false;
    /*最高跳跃速度备份*/
    user.initJunpVBuff = 20;
    /*确认死亡*/
    user.death = false;
    /*死亡延迟*/
    user.deathYc = 5;
    /*死亡延迟的一个对比*/
    user.deathYcCon = 0;
    /*子弹数量*/
    user.zdNum = 5;
    /*生命值*/
    user.SMZ = 5;
    /*平A*/
    user.AtimeJG = 100;
    user.Atime = 0;
    user.skillBool = true;
    user.skillBoolJG = 2000;
    user.WH = WH;
    /*图片频率演出*/
    user.IMG_YC = 0;
    user.img = null;
    if (typeof img === "string") {
      user.img = new Image();
      user.img.src = img;
    } else {
      user.img = img;
    }
    user.moveLeftGo = true;
    user.zdOk = true;
    user.deathFun = (global) => {
      // global.gameIsEnd("Game Over");
    }
    user.leftRight = (terrainArr) => {
      if (user.deathIng === true) {
        return 0;
      }
      var ltW = Math.floor(user.x / user.w);
      var ltH = Math.floor(user.y / user.h);
      var around = this.getAround(this.terrainArr, ltH * terrainArr.WH.split("|")[0] + ltW);
      var around02 = this.getAround(this.terrainArr, ltH * terrainArr.WH.split("|")[0] + ltW + 1);
      this.IMG_CHANGE();
      switch (around[2]) {
        case 1:
          {
            user.moveLeftGo = false;
            break;
          }
        case 0:
          {
            if (user.moveLeftGo === true) {
              user.x = user.x + 2;
            }
            break;
          }
        default:
          {
            user.moveLeftGo = false;
            break;
          }
      }
      switch (around02[0]) {
        case 1:
          {
            user.moveLeftGo = true;
            break;
          }
        case 0:
          {
            if (user.moveLeftGo === false) {
              user.x = user.x - 2;
            }
            break;
          }
        default:
          {
            user.moveLeftGo = true;
            break;
          }
      }
      switch (around02[2]) {
        case 1:
          {
            user.x = user.x - 2;
            user.moveLeftGo = false;
            break;
          }
        default:
          {
            user.moveLeftGo = false;
            break;
          }
      }
      if (around[8] === 0) {
        user.moveLeftGo = false;
      }
      if (around02[6] === 0) {
        user.moveLeftGo = true;
      }
    };
    /*怪物发射子弹函数*/
    user.zdFun = (a, b) => {
      if (Math.abs(b.userObj.y - user.y) <= 50 && Math.abs(b.userObj.x - user.x) <= 100 && user.zdOk === true &&
        user.zdNum > 0) {
        user.zdOk = false;
        var THIS = user;
        setTimeout(function() {
          THIS.zdOk = true;
        }, 1500);
        user.zdNum--;
        user.A(b);
      }
    };
    user.A = (O) => {
      if (user.Atime !== 0) {
        return 0;
      }
      var THIS = user;
      user.Atime = setTimeout(function() {
        clearTimeout(THIS.Atime);
        THIS.Atime = 0;
      }, user.AtimeJG);
      var zd = this.gjA(THIS.moveLeftGo === false ? THIS.x : THIS.x + this.w, THIS.y + 3, O.loadRes[0].width, O.loadRes[
        0].height, O.loadRes[0]);
      O.zdB.push(zd);
      zd.movePoint({
        x: O.userObj.x,
        y: O.userObj.y
      }, 350, function() {
        for (var i = 0; i < O.zdB.length; i++) {
          if (O.zdB[i] === zd) {
            O.zdB.splice(i, 1);
          }
        }
      }, {
        interFun: O.zdTrien
      });
    };
    /*向下落的函数*/
    user.moveBottom = (terrainArr) => {
      if (user.moveBottomBool === false) {
        return 1;
      }
      if (user.death === true) {
        user.vBottm = user.vBottm + 4;
        user.y = user.y + user.vBottm;
        user.deathYcCon++;
        if (user.deathYc === user.deathYcCon) {
          return "death";
        }
        return "deathIng";
      }
      var moveBottom_Con = false;
      var WHlx = Math.floor(user.x / user.w);
      if (/^[0-9]*$/.test(user.x / user.w)) {
        moveBottom_Con = true;
      }
      var WHly = Math.floor((user.y) / user.h);
      var around = this.getAround(terrainArr, WHly * terrainArr.WH.split("|")[0] + WHlx);
      var WHrx = Math.floor((user.x + user.w) / user.w);
      if (/^[0-9]*$/.test((user.x + user.w) / user.w)) {
        moveBottom_Con = true;
      }
      var WHry = Math.floor((user.y) / user.h);
      var around2 = this.getAround(terrainArr, WHry * terrainArr.WH.split("|")[0] + WHrx);
      var WHcx = Math.floor((user.x + user.w / 2) / user.w);
      var around3 = this.getAround(terrainArr, WHry * terrainArr.WH.split("|")[0] + WHcx);
      if (around3[7] === 1) {
        moveBottom_Con = false;
      }
      var THIS = user;

      function type0() {
        if (THIS.vBottm > THIS.vMaxBottm) {
          THIS.vBottm = THIS.vBottm + 4;
        } else {
          THIS.vBottm = THIS.vMaxBottm;
        }
        THIS.y = THIS.y + THIS.vBottm;
      }

      function type1() {
        THIS.initJunpV = THIS.initJunpVBuff;
        if (THIS.y + THIS.vBottm > WHly * THIS.h) {
          THIS.y = WHly * THIS.h;
        } else {
          if (around[2] === 0) {
            THIS.y = THIS.y + THIS.vBottm;
          }
        }
      }
      if (around[7] === 0 && around2[7] === 0) {
        type0();
      } else if (around[7] === 1 && around2[7] === 0) {
        if (moveBottom_Con) {
          type0();
        } else {
          type1();
        }
      } else if (around[7] === 0 && around2[7] === 1) {
        if (moveBottom_Con) {
          type0();
        } else {
          type1();
        }
      } else if (around[7] === 1 && around2[7] === 1) {
        type1();
      }
    };
    // 左移动方法
    user.moveLeft = (terrainArr) => {
      user.initWH();
      user.Ih = 2;
      if (user.IMG_YC >= 2) {
        if (user.Iw >= 2) {
          user.Iw = 0
        } else {
          user.Iw++;
        }
        user.IMG_YC = 0;
      } else {
        user.IMG_YC++;
      }
      user.fx = "left";
      var around = this.getAround(terrainArr, user.WH.split("|")[1] * terrainArr.WH.split("|")[0] + parseInt(user.WH
        .split("|")[0]));
      var around2 = this.getAround(terrainArr, user.WH.split("|")[1] * terrainArr.WH.split("|")[0] + parseInt(user.WH
        .split("|")[0]) + 1);
      switch (around[2]) {
        case 0:
          {
            if (user.vLeft <= user.vMaxLeft) {
              user.vLeft = user.vLeft + user.leftG * 0.5;
            } else {
              user.vLeft = user.vMaxLeft;
            }
            switch (around2[2]) {
              case 1:
                {
                  user.vLeft = ((parseInt(user.WH.split("|")[0]) + 1) * user.w - user.x) / 1.5 + 1;
                  user.x = user.x + user.vLeft;
                  break;
                }
              case 0:
                {
                  user.x = user.x + user.vLeft;
                  break;
                }
              default:
                {
                  user.vLeft = ((parseInt(user.WH.split("|")[0]) + 1) * user.w - user.x) / 1.5 + 1;
                  user.x = user.x + user.vLeft;
                  break;
                }
            }
            break;
          }
        default:
          {
            user.x = user.WH.split("|")[0] * user.w;
            user.vLeft = 0;
          }
      }
    };
    // 右移动方法
    user.moveRight = (terrainArr) => {
      user.initWH();
      user.Ih = 1;
      if (user.IMG_YC >= 2) {
        if (user.Iw >= 2) {
          user.Iw = 0
        } else {
          user.Iw++;
        }
        user.IMG_YC = 0;
      } else {
        user.IMG_YC++;
      }
      user.fx = "right";
      var around = this.getAround(terrainArr, user.WH.split("|")[1] * terrainArr.WH.split("|")[0] + parseInt(user.WH
        .split("|")[0]));
      switch (around[0]) {
        case 0:
          {
            if (user.vLeft < user.vMaxLeft) {
              user.vLeft = user.vLeft + 5;
            } else {
              user.vLeft = user.vMaxLeft;
            }
            user.x = user.x - user.vLeft;
            break;
          }
        case 1:
          {
            user.x = user.WH.split("|")[0] * user.w;
            user.vLeft = 0;
            break;
          }
        default:
          {
            user.x = user.WH.split("|")[0] * user.w;
            user.vLeft = 0;
            break;
          }
      }
    };
    // 跳跃方法
    user.moveTop = (terrainArr) => {
      user.initWH();
      user.moveBottomBool = false;
      var around = this.getAround(terrainArr, user.WH.split("|")[1] * terrainArr.WH.split("|")[0] + parseInt(user.WH
        .split("|")[0]));
      switch (around[4]) {
        case 0:
          {
            if (user.initJunpV > 0) {
              user.initJunpV = user.initJunpV - 4;
            } else {
              user.initJunpV = 0;
              user.moveBottomBool = true;
            }
            user.y = user.y - user.initJunpV;
            break;
          }
        default:
          {
            user.vBottm = 0;
            user.initJunpV = 0;
            user.moveBottomBool = true;
            break;
          }
      }
    };
    user.initWH = () => {
      user.WH = Math.floor(user.x / user.w) + "|" + Math.floor(user.y / user.h);
    };
    /*动画方法*/
    user.movePoint = (P, time, f, o) => {
      if (user.timmer) {
        clearInterval(user.timmer);
      }
      if (time === -1) {
        user.x = typeof P.x !== "undefined" ? P.x : user.x;
        user.y = typeof P.y !== "undefined" ? P.y : user.y;
        return 1;
      }
      var moveJGx = (user.x - P.x) / time;
      var moveJGy = (user.y - P.y) / time;
      var THIS = user;
      var num = 0;
      user.timmer = setInterval(() => {
        num++;
        if (num >= time) {
          if (typeof P.x !== "undefined") {
            THIS.x = P.x;
          }
          if (typeof P.y !== "undefined") {
            THIS.y = P.y;
          }
          clearInterval(THIS.timmer);
          if (f) {
            f(THIS);
          }
        } else {
          if (typeof P.x !== "undefined") {
            THIS.x = THIS.x - moveJGx
          }
          if (typeof P.y !== "undefined") {
            THIS.y = THIS.y - moveJGy;
          }
        }
        if (o && o.interFun) {
          o.interFun(THIS, o.constructor === this.user ? "zdA" : "zdB");
        }
      }, 1)
    }
    return user
  };
  emptyArr(arr) {
    arr.splice(0, arr.length);
  };
  /*0 左边 1当前 2右边 3上左 4上中 5上右 6下左 7下中 8下右*/
  getAround(arr, a) {
    if (a < 0 || a >= this.mapUI[0].length) {
      console.log("getAround arguments is error");
      return -1;
    }
    this.emptyArr(this.Around);
    var returnArr = [];
    var w = Math.floor(a / this.getWidth());
    for (var i = 0; i < 3; i++) {
      if (a - 1 + i >= w * this.getWidth() && a - 1 + i < (w + 1) * this.getWidth()) {
        if (typeof this.mapUI[0][a - 1 + i] != "undefined") {
          this.Around.push(this.mapUI[0][a - 1 + i]);
        } else {
          this.Around.push(null);
        }
      } else {
        this.Around.push(null);
      }
    }
    for (i = 0; i < 3; i++) {
      if (a - 1 + i - this.getWidth() >= (w - 1) * this.getWidth() && a - 1 + i - this.getWidth() < (w) * this.getWidth()) {
        if (typeof this.mapUI[0][a - 1 + i - this.getWidth()] != "undefined") {
          this.Around.push(this.mapUI[0][a - 1 + i - this.getWidth()]);
        } else {
          this.Around.push(null);
        }
      } else {
        this.Around.push(null);
      }

    }
    for (i = 0; i < 3; i++) {
      if (a - 1 + i + this.getWidth() >= (w + 1) * this.getWidth() && a - 1 + i + this.getWidth() < (w + 2) * this.getWidth()) {
        if (typeof this.mapUI[0][a - 1 + i + this.getWidth()] != "undefined") {
          this.Around.push(this.mapUI[0][a - 1 + i + this.getWidth()]);
        } else {
          this.Around.push(null);
        }
      } else {
        this.Around.push(null);
      }
    }
    returnArr = [].concat(this.Around);
    return returnArr;
  };
  //游戏结束触发函数只是对于清除了中心计时器
  gameIsEnd(t) {
    clearInterval(this.centerTime);
    window.cancelAnimationFrame(this.animateA);
    this.submit2d.clearRect(0, 0, this.canvassubmit.width, this.canvassubmit.height);
    this.submit2d.fillStyle = "#000000";
    this.submit2d.font = "32px Georgia";
    this.submit2d.fillRect(0, 0, this.canvassubmit.width, this.canvassubmit.height);
    var gradient = this.submit2d.createLinearGradient(0, 0, this.canvassubmit.width, 0);
    gradient.addColorStop("0", "#666666");
    gradient.addColorStop("0.25", "#eeeeee");
    gradient.addColorStop("1.0", "#ffffff");
    this.submit2d.fillStyle = gradient;
    this.submit2d.textBaseline = "middle";
    this.submit2d.textAlign = "center";
    this.submit2d.fillText(t, this.canvassubmit.width / 2, this.canvassubmit.height / 2);
  };
  // 绘制图片
  drawImg(type, i) {
    switch (type) {
      case 0:
        {
          break;
        }
      default:
        {
          this.terrainArr.push(this.terrains(0, 0, this.w, this.h, 1, this.getNowWH(this.mapUI[this.gs], i),
            "Image/terrain/terrain1.png", true));
          break;
        }
    }
  }
  getWidth() {
    if (this.mapUI[0].WH === null) {
      console.log("Now this.WH is null");
      return -1;
    }
    if (this.mapUI[0].WH.split("|").length === 2) {
      return parseInt(this.mapUI[0].WH.split("|")[0]);
    } else {
      console.log("Now this.WH is error");
      return -1;
    }
  };
  getNowWH(mapui, i) {
    var w = i % 240;
    var h = Math.floor(i / 240);
    return w + '|' + h;
  };
  //type 类型 jzImg 比列 4x1 defaultFun 默认函数 deathYc 死亡延迟怪物类型
  monster(x, y, w, h, WH, img, type, jzImg, defaultFun, dtathYc, prototypeAttr) {
    // global.call(this,x,y,w,h);
    var monster = {}; //创建对象，对象属性赋值
    monster.x = x; //x
    monster.y = y; //y
    monster.w = w; //宽度
    monster.h = h; //高度
    /*对象计时器 如果对帧的概念不是很了解还是别看这个属性了*/
    monster.timmer = 0;
    /*像做的加速度*/
    monster.leftG = 10;
    /*向下移动速度*/
    monster.vBottm = 0;
    /*最大速度*/
    monster.vMaxBottm = 10;
    /*初始化速度*/
    monster.vLeft = 0;
    /*最大移动速度*/
    monster.vMaxLeft = 8;
    /*跳跃速度*/
    monster.initJunpV = 20;
    /*正在死亡*/
    monster.deathIng = false;
    /*最高跳跃速度备份*/
    monster.initJunpVBuff = 20;
    monster.Ih = 0;
    monster.Iw = 0;
    /*确认死亡*/
    monster.death = false;
    /*死亡延迟*/
    monster.deathYc = 5;
    /*死亡延迟的一个对比*/
    monster.deathYcCon = 0;
    /*子弹数量*/
    monster.zdNum = 2;
    /*生命值*/
    monster.SMZ = 5;
    /*平A*/
    monster.AtimeJG = 100;
    monster.Atime = 0;

    monster.skillBool = true;
    monster.skillBoolJG = 2000;

    monster.SMZ = 2;
    monster.type = type;
    monster.jzImg = jzImg;
    monster.WH = WH;
    /*图片频率演出*/
    monster.IMG_YC = 0;
    monster.defaultFun = defaultFun ? defaultFun : "leftRight";
    if (typeof img === "string") {
      monster.img = new Image();
      monster.img.src = img;
    } else {
      monster.img = img;
    }
    monster.deathYc = dtathYc;
    monster.deathYcCon = 0;
    monster.moveLeftGo = true;
    monster.zdOk = true;
    if (typeof prototypeAttr != "object") {
      monster.prototypeAttr = prototypeAttr;
    } else {
      var keys = Object.keys(prototypeAttr);
      for (var i = 0; i < keys.length; i++) {
        monster[keys[i]] = prototypeAttr[keys[i]];
      }
    }
    monster.deathFun = (global) => {
      // global.gameIsEnd("Game Over");
      global.gameIsEnd("Game Over")
    }
    monster.leftRight = (terrainArr) => {
      if (monster.deathIng === true) {
        return 0;
      }
      var ltW = Math.floor(monster.x / monster.w);
      var ltH = Math.floor(monster.y / monster.h);
      var around = this.getAround(this.terrainArr, ltH * terrainArr.WH.split("|")[0] + ltW);
      var around02 = this.getAround(this.terrainArr, ltH * terrainArr.WH.split("|")[0] + ltW + 1);

      this.IMG_CHANGE();
      switch (around[2]) {
        case 1:
          {
            monster.moveLeftGo = false;
            break;
          }
        case 0:
          {
            if (monster.moveLeftGo === true) {
              monster.x = monster.x + 2;
            }
            break;
          }
        default:
          {
            monster.moveLeftGo = false;
            break;
          }
      }
      switch (around02[0]) {
        case 1:
          {
            monster.moveLeftGo = true;
            break;
          }
        case 0:
          {
            if (monster.moveLeftGo === false) {
              monster.x = monster.x - 2;
            }
            break
          }
        default:
          {
            monster.moveLeftGo = true;
            break;
          }
      }
      switch (around02[2]) {
        case 1:
          {
            monster.x = monster.x - 2;
            monster.moveLeftGo = false;
            break;
          }
        default:
          {
            monster.moveLeftGo = false;
            break;
          }
      }
      if (around[8] === 0) {
        monster.moveLeftGo = false;
      }
      if (around02[6] === 0) {
        monster.moveLeftGo = true;
      }
    };
    /*怪物发射子弹函数*/
    monster.zdFun = (a, b) => {
      if (Math.abs(b.userObj.y - monster.y) <= 50 && Math.abs(b.userObj.x - monster.x) <= 100 && monster.zdOk ===
        true && monster.zdNum > 0) {
        console.log(a, b)
        monster.zdOk = false;
        var THIS = monster;
        setTimeout(function() {
          THIS.zdOk = true;
        }, 1500);
        monster.zdNum--;
        monster.A(b);
      }
    };
    monster.A = (O) => {
      console.log(O)
      console.log(monster.Atime)
      if (monster.Atime !== 0) {
        return 0;
      }
      var THIS = monster;
      monster.Atime = setTimeout(() => {
        clearTimeout(THIS.Atime);
        THIS.Atime = 0;
      }, monster.AtimeJG);
      var zd = this.gjA(THIS.moveLeftGo === false ? THIS.x : THIS.x + monster.w, THIS.y + 3,
        O.loadRes[0].width,
        O.loadRes[0].height, O.loadRes[0]);
      console.log(zd)
      O.zdB.push(zd);
      zd.movePoint({
        x: O.userObj.x,
        y: O.userObj.y
      }, 350, function() {
        for (var i = 0; i < O.zdB.length; i++) {
          if (O.zdB[i] === zd) {
            O.zdB.splice(i, 1);
          }
        }
      }, {
        interFun: O.zdTrien
      });
    };
    /*向下落的函数*/
    monster.moveBottom = (terrainArr) => {
      if (monster.moveBottomBool === false) {
        return 1;
      }
      if (monster.death === true) {
        monster.vBottm = monster.vBottm + 4;
        monster.y = monster.y + monster.vBottm;
        monster.deathYcCon++;
        if (monster.deathYc === monster.deathYcCon) {
          return "death";
        }
        return "deathIng";
      }
      var moveBottom_Con = false;
      var WHlx = Math.floor(monster.x / monster.w);
      if (/^[0-9]*$/.test(monster.x / monster.w)) {
        moveBottom_Con = true;
      }
      var WHly = Math.floor((monster.y) / monster.h);
      var around = this.getAround(terrainArr, WHly * terrainArr.WH.split("|")[0] + WHlx);
      var WHrx = Math.floor((monster.x + monster.w) / monster.w);
      if (/^[0-9]*$/.test((monster.x + monster.w) / monster.w)) {
        moveBottom_Con = true;
      }
      var WHry = Math.floor((monster.y) / monster.h);
      var around2 = this.getAround(terrainArr, WHry * terrainArr.WH.split("|")[0] + WHrx);
      var WHcx = Math.floor((monster.x + monster.w / 2) / monster.w);
      var around3 = this.getAround(terrainArr, WHry * terrainArr.WH.split("|")[0] + WHcx);
      if (around3[7] === 1) {
        moveBottom_Con = false;
      }

      var THIS = monster;

      function type0() {
        if (THIS.vBottm > THIS.vMaxBottm) {
          THIS.vBottm = THIS.vBottm + 4;
        } else {
          THIS.vBottm = THIS.vMaxBottm;
        }
        THIS.y = THIS.y + THIS.vBottm;
      }

      function type1() {
        THIS.initJunpV = THIS.initJunpVBuff;
        if (THIS.y + THIS.vBottm > WHly * THIS.h) {
          THIS.y = WHly * THIS.h;
        } else {
          if (around[2] === 0) {
            THIS.y = THIS.y + THIS.vBottm;
          }
        }
      }
      if (around[7] === 0 && around2[7] === 0) {
        type0();
      } else if (around[7] === 1 && around2[7] === 0) {
        if (moveBottom_Con) {
          type0();
        } else {
          type1();
        }

      } else if (around[7] === 0 && around2[7] === 1) {
        if (moveBottom_Con) {
          type0();
        } else {
          type1();
        }
      } else if (around[7] === 1 && around2[7] === 1) {
        type1();
      }
    };
    //this.moveWidth=moveWidth;//移动改变的距离
    //this.g=10;//下落加速度
    //this.speedG=3;//左右移动加速度
    //this.junpG=100;//跳跃加速度
    /*动画方法*/
    monster.movePoint = (P, time, f, o) => {
      if (monster.timmer) {
        clearInterval(monster.timmer);
      }
      if (time === -1) {
        monster.x = typeof P.x !== "undefined" ? P.x : monster.x;
        monster.y = typeof P.y !== "undefined" ? P.y : monster.y;
        return 1;
      }
      var moveJGx = (monster.x - P.x) / time;
      var moveJGy = (monster.y - P.y) / time;
      var THIS = monster;
      var num = 0;
      monster.timmer = setInterval(() => {
        num++;
        if (num >= time) {
          if (typeof P.x !== "undefined") {
            THIS.x = P.x;
          }
          if (typeof P.y !== "undefined") {
            THIS.y = P.y;
          }

          clearInterval(THIS.timmer);
          if (f) {
            f(THIS);
          }
        } else {
          if (typeof P.x !== "undefined") {
            THIS.x = THIS.x - moveJGx
          }
          if (typeof P.y !== "undefined") {
            THIS.y = THIS.y - moveJGy;
          }
        }
        if (o && o.interFun) {
          o.interFun(THIS, o.constructor === this.user ? "zdA" : "zdB");
        }
      }, 1)
    }

    return monster
  };
  IMG_CHANGE() {
    switch (this.jzImg) {
      case "4x1":
        {
          this.Iw = 0;
          this.Ih = 0;
          break;
        }
      case "4x2":
        {
          if (this.moveLeftGo === true) {
            this.Ih = 1;
            if (this.IMG_YC >= 4) {
              if (this.Iw >= 3) {
                this.Iw = 2;
              } else {
                this.Iw++;
              }
              this.IMG_YC = 0;
            } else {
              this.IMG_YC++;
            }

          } else {
            this.Ih = 0;
            if (this.IMG_YC >= 4) {
              if (this.Iw >= 3) {
                this.Iw = 2;
              } else {
                this.Iw++;
              }
              this.IMG_YC = 0;
            } else {
              this.IMG_YC++;
            }
          }
          break;
        }
      default:{
        return
      }
    }
  };
  //初始化地形类
  terrains(x, y, w, h, type, WH, img, consotion) {
    // global.call(this,x,y,w,h);
    var terrains = {}; //创建对象，对象属性赋值
    if (typeof consotion == "undefined") {
      consotion = false;
    }
    terrains.x = x; //x
    terrains.y = y; //y
    terrains.w = w; //宽度
    terrains.h = h; //高度
    /*对象计时器 如果对帧的概念不是很了解还是别看这个属性了*/
    terrains.timmer = 0;
    /*像做的加速度*/
    terrains.leftG = 10;
    /*向下移动速度*/
    terrains.vBottm = 0;
    /*最大速度*/
    terrains.vMaxBottm = 10;
    /*初始化速度*/
    terrains.vLeft = 0;
    /*最大移动速度*/
    terrains.vMaxLeft = 8;
    /*跳跃速度*/
    terrains.initJunpV = 20;
    /*正在死亡*/
    terrains.deathIng = false;
    /*最高跳跃速度备份*/
    terrains.initJunpVBuff = 20;
    terrains.Ih = 0;
    terrains.Iw = 0;
    /*确认死亡*/
    terrains.death = false;
    /*死亡延迟*/
    terrains.deathYc = 5;
    /*死亡延迟的一个对比*/
    terrains.deathYcCon = 0;
    /*子弹数量*/
    terrains.zdNum = 2;
    /*生命值*/
    terrains.SMZ = 5;

    terrains.type = type;
    terrains.WH = WH;
    terrains.img = null;
    if (typeof img === "string") {
      terrains.img = new Image();
      terrains.img.src = img;
    } else {
      terrains.img = img;
    }
    terrains.consotion = consotion;
    return terrains
  }
  // 监听全局按键事件
  handleEven() {
    window.onkeydown = (e) => {
      if (e.keyCode === 65) {
        console.log('a')
        // this.userObj.moveRight(mapUI[this.gs]);
        this.userObjRight = true;
        this.userMove = true;
        //this.leftViewX();

      }
      if (e.keyCode === 68) {
        console.log('b')
        this.userObjLeft = true;
        this.userMove = true;
        //this.userObj.moveLeft(mapUI[this.gs]);
        // this.rightViewX();
      }
      if (e.keyCode === 87) {
        console.log('b')
        this.userObjTop = true;
        //this.userObj.moveTop(mapUI[this.gs]);
        this.userMove = true;
      }
    };
    window.onkeyup = (e) => {
      if (e.keyCode === 87) {
        this.userObjTop = false;
      }
      if (e.keyCode === 68) {
        this.userObjLeft = false;
      }
      if (e.keyCode === 65) {
        this.userObjRight = false;
      }
      if (e.keyCode === 87 || e.keyCode === 68 || e.keyCode === 65) {
        this.userObj.moveBottomBool = true;
        this.userMove = false;
      }
      if (e.keyCode === 74) {
        if (this.userObj.death === true || this.userObj.zdNum <= 0) {
          return 0;
        }
        var zd = this.gjA(this.userObj.fx === "right" ? this.userObj.x : this.userObj.x + this.userObj.w, this.userObj
          .y + 3, this.loadRes[0].width, this.loadRes[0].height, this.loadRes[0]);
        this.userObj.zdNum--;
        zd.zdLen = this.zdA.length;
        this.zdA.push(zd);
        zd.movePoint({
          x: this.userObj.fx === "right" ? zd.x - 80 : zd.x + 80,
          y: zd.y
        }, 100, function() {
          for (var i = 0; i < this.zdA.length; i++) {
            if (this.zdA[i] === zd) {
              this.zdA.splice(i, 1);
            }
          }
        }, {
          interFun: this.zdTrien
        });
      }
    };
  }
  componentDidMount = () => {
    // 挂载
    this.startFun()
    this.handleEven()
  };
};
