import React, {Component} from 'react';
import DocumentMeta from 'react-document-meta';
import { CanvasRain } from '../packages/index.js'
class Home extends Component {
  constructor(props) {
    super(props);
    this.state={ }
  }
  render(){
    console.log(this.props)
    const meta = {
      title: 'ZMZUI-react - 一款超简洁react.js的模块化开发的PC网站快速成型UI组件库',
      description: '追梦猪旗下开发基于react.js的UI组件库,实现快速搭建网站应用,集成零散插件,帮助开发者快速开发,如没有相应插件,可联系作者进行组件开发!',
      meta: {
        charset: 'utf-8',
        name: {
          keywords: 'react,react.js,react.js插件,react.js插件开发,reactUI,reactUI组件,reactUI组件开发'
        }
      }
    };
    return (
      <DocumentMeta {...meta}>
        <div className="zmz-react">
        {/*<CanvasConnection data="我是canvas">111</CanvasConnection>*/}
        <CanvasRain></CanvasRain>
        </div>
      </DocumentMeta>
    );
  }
  componentDidMount=()=>{
    console.log(2);
    var params = {
      keywords:'海阔天空',
    }
    React.$http.get('http://localhost:3000/music/v1/search',params).then(function(res) {
  				console.log(res)
  				if(res.data.Code === 0){
  				}else{
            console.log(res.data.result.songs[0].id)
            var params = {
              id:res.data.result.songs[0].id,
               br:'999000'
            }
            React.$http.get('http://localhost:3000/music/v1/music/url',params).then(function(res) {
            			console.log(res)
            			if(res.data.Code === 0){
            			}else{

            			}
            		})
  				}
  			})
  };

}
export default Home;
