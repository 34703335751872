import React from 'react';
// import {BrowserRouter as Router , Route} from 'react-router-dom';// history模式
import {HashRouter as Router , Route} from 'react-router-dom'; // hash模式
import Demo from '../views/demo1'
import Demo2 from '../views/demo2'
import Demo4 from '../views/demo4'
import Header from '../views/public/Header'
import Home from '../views/home';
import Game from '../views/game';
export default (
  <Router>
      <Header/>
      <div className="zmz-react-section">
        <div className="zmz-react-pt80">
          <Route exact path="/" component={Home} />
          <Route exact path="/game" component={Game} />
          <Route  path="/Demo" component={Demo} />
          <Route  path="/Demo2" component={Demo2} />
          <Route  path="/Demo4" component={Demo4} />
        </div>
      </div>
  </Router>
);
