import React, {Component} from 'react';
export default class CanvasConnection extends Component {
  constructor(props) {
    super(props);
    this.state={ }
  }
  render(){
    return (
      <canvas id="submit" data={this.props.data}>您的浏览器不支持此标签,请更换最新浏览器</canvas>
    );
  }
  //canvas 动画
  init () {
    // 初始化函数
    this.canvas = document.getElementById('submit');
    this.canvas.width =  document.documentElement.clientWidth||document.body.clientWidth||window.innerWidth;
    this.canvas.height = document.documentElement.clientHeight||document.body.clientHeight||window.innerHeight;
    this.canvas.style.position = 'fixed'
    this.canvas.style.top = '0'
    this.canvas.style.left = '0'
    this.randomDraw = [];
    this.randomDrawAll = [];
    this.mousePage = {'x':'','y':'','max':20000}
    this.ctx = this.canvas.getContext('2d');
    //随机生成config.n条线位置信息
    for (var i = 0; 199 > i; i++) {
      this.x = this.randomNum(3, this.canvas.width - 3)
      this.y = this.randomNum(3, this.canvas.height - 3)
      this.xa = 2 * Math.random() - 1
      this.ya = 2 * Math.random() - 1
      this.randomDraw.push({
        x: this.x,
        y: this.y,
        xa: this.xa,
        ya: this.ya,
        max: 6000 //沾附距离
      });
    }
    // 合并原始位置数组和鼠标划过位置
    this.randomDrawAll = this.randomDraw.concat([this.mousePage]);
    //默认显示
    this.draw();
    // 鼠标经过
    window.onmousemove =  (e) => {
      var event = e || window.event;
      this.mousePage.x = event.clientX;
      this.mousePage.y = event.clientY;
    }
    window.onmouseout = () => {
       this.mousePage.x = null;
       this.mousePage.y = null;
    };
    // 监听窗口变化
    window.addEventListener('resize',() =>{
      this.canvas.width =  document.documentElement.clientWidth||document.body.clientWidth||window.innerWidth;
      this.canvas.height = document.documentElement.clientHeight||document.body.clientHeight||window.innerHeight;
    })
  }
  animations () {
    // 渲染点
    window.requestAnimationFrame(()=>{
      this.draw()
    })
  }
  draw() {
    // 画点线
    this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);
    //随机的线条和当前位置联合数组
    var e; //临时节点
    var i;
    //遍历处理每一个点
    this.randomDraw.forEach((r, idx) => {
        r.x += r.xa;
        r.y += r.ya;
        r.xa *= r.x > this.canvas.width || r.x < 0 ? -1 : 1;
        r.ya *= r.y > this.canvas.height || r.y < 0 ? -1 : 1;
        this.ctx.fillRect(r.x - 0.5, r.y - 0.5, 1, 1);
      //从下一个点开始
      for (i = idx + 1; i < this.randomDrawAll.length; i++) {
        e = this.randomDrawAll[i];
        // e == e
        // r == r
        //不是当前点
        if(e===r||e.x===null||e.y===null) continue;//continue跳出当前循环开始新的循环
						var dx = r.x - e.x;//别的点坐标减当前点坐标
						var dy = r.y - e.y;
						var dc = dx*dx + dy*dy;
						if(Math.sqrt(dc)>Math.sqrt(e.max)) continue;
						// 如果是鼠标，则让粒子向鼠标的位置移动
						if (e === this.mousePage && Math.sqrt(dc) >Math.sqrt(e.max)/2) {
							r.x -= dx * 0.02;
							r.y -= dy * 0.02;
						}
						var ratio;
						ratio = (e.max - dc) / e.max;
						this.ctx.beginPath();
						this.ctx.lineWidth = ratio / 2;
            this.ctx.strokeStyle = "rgba(143, 148, 171," + (ratio+ 0.2) + ")";
						this.ctx.moveTo(r.x, r.y);
						this.ctx.lineTo(e.x, e.y);
						this.ctx.stroke();//不描边看不出效果
      }
    })
    this.animations()
  }
  randomNum (m, n) {
    // 随机位置工具方法
    return Math.floor(Math.random() * (n - m + 1) + m);
  }
  randomColor () {
    // 随机位置工具方法
    return "rgb(" + this.randomNum(0, 255) + "," + this.randomNum(0, 255) + "," + this.randomNum(0, 255) + ")";
  }
  componentDidMount=()=>{
    // 挂载
    this.init()
  };
};
