import React, {Component} from 'react';
import { Textarea, Button } from '../packages/index.js'
import DocumentMeta from 'react-document-meta';
class Demo1 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data:'[{"name":"Google","url":"<img src=\' //img0.qidian.com/upload/gamesy/2019/08/15/20190815111920ijmg26dqly.jpg\'>"},{"name":"Baidu","url":"http://www.baidu.com"},{"name":"SoSo","url":"http://www.SoSo.com"}]'
    };
  }
  render(){
    const meta = {
      title: 'ZMZUI-react - 一款超简洁react.js的模块化开发的PC网站快速成型UI组件库',
      description: '追梦猪旗下开发基于react.js的UI组件库,实现快速搭建网站应用,集成零散插件,帮助开发者快速开发,如没有相应插件,可联系作者进行组件开发!',
      meta: {
        charset: 'utf-8',
        name: {
          keywords: 'react,react.js,react.js插件,react.js插件开发,reactUI,reactUI组件,reactUI组件开发'
        }
      }
    };
    return (
      <DocumentMeta {...meta}>
        <div className="zmz-react-json">
          <div className="zmz-react-json__textarea">
            <Textarea defaultvalue={this.state.data} handleTextarea={this.handleChang.bind(this)}/>
          </div>
          <div className="zmz-react-json__button">
          <span onClick={()=>{this.handleClick1()}}>
            <Button type="success" disabled={true} active={true} >格式化</Button>
          </span>
          <span onClick={()=>{this.handleClick2()}}>
            <Button type="info" disabled={true} active={true} >压缩</Button>
          </span>
          </div>
        </div>
      </DocumentMeta>
    );
  }
  handleChang(event){
    console.log(event)
    this.setState({
      data:event.target.value
    })
  };
  handleClick1 = () => {
    var json = this.state.data
    if (typeof json == 'string') {
        try {
            var obj=JSON.parse(json);
            if(typeof obj == 'object' && obj ){
                var a =  JSON.parse(json);
                var b =  JSON.stringify(a,null,4);
                console.log(b)
                this.setState({
                  data:b
                })
                return true;
            }else{
                return false;
            }
        } catch(e) {
          console.log(e)
            alert('json格式错误请检查:'+e.message);
            return false;
        }
    }
  };
  handleClick2 = () => {
    var json = this.state.data
    if (typeof json == 'string') {
        try {
            var obj=JSON.parse(json);
            if(typeof obj == 'object' && obj ){
                var a =  JSON.parse(json);
                var b =  JSON.stringify(a);
                console.log(b)
                this.setState({
                  data:b
                })
                return true;
            }else{
                return false;
            }
        } catch(e) {
          console.log(e)
            alert('json格式错误请检查:'+e.message);
            return false;
        }
    }
  };
  componentWillMount=()=>{
    console.log(1);
  };
  componentDidMount=()=>{
    console.log(2);
  };
  componentWillReceiveProps=()=>{
    console.log(3);
  };
  shouldComponentUpdate=()=>{
    console.log(4);
    return true;
  };
  componentWillUpdate=()=>{
    console.log(5);
  };
  componentDidUpdate=()=>{
    console.log(6);
  };
  componentWillUnmount=()=>{
    console.log(7);
  };
}

export default Demo1;
